import "./main.css";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC2sUX5pV5-iyVpG9N4w_-LaQ0bKlMGB6E",
  authDomain: "davidlemlerm-todo-learning.firebaseapp.com",
  projectId: "davidlemlerm-todo-learning",
  storageBucket: "davidlemlerm-todo-learning.appspot.com",
  messagingSenderId: "428168035184",
  appId: "1:428168035184:web:c4a2d41159d617385c2db5",
  measurementId: "G-LRQ44L9SX3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth();
const provider = new GoogleAuthProvider();
const firestore = getFirestore();

let thisUser, userTodos;

const signedOut = document.getElementById("signed-out");
const signInWithGoogleButton = document.getElementById("sign-in-with-google");
const signedIn = document.getElementById("signed-in");
const userDetails = document.getElementById("user-details");
const todosList = document.getElementById("todos-list");
const newTodoForm = document.getElementById("new-todo-form");
const newTodoName = document.getElementById("new-todo");
const signOutButton = document.getElementById("sign-out");
function hide(element) {
  const hiddenClass = "hidden";
  if (!element.classList.contains(hiddenClass)) {
    element.classList.add(hiddenClass);
  }
}
function show(element) {
  const hiddenClass = "hidden";
  if (element.classList.contains(hiddenClass)) {
    element.classList.remove(hiddenClass);
  }
}
function clearTodosList() {
  for (let listDoc = todosList.children.length - 1; listDoc >= 0; listDoc--) {
    todosList.children[listDoc].remove();
  }
}

signInWithGoogleButton.addEventListener("click", () => {
  signInWithPopup(auth, provider)
    .then((result) => {
      thisUser = doc(firestore, `/users/${auth.currentUser.uid}`);
      userTodos = collection(thisUser, "/todos");
      userDetails.textContent = `Hello ${result.user.displayName}`;
      onSnapshot(userTodos, (docs) => {
        clearTodosList();
        docs.forEach((doc) => {
          const listChild = document.createElement("li");
          listChild.textContent = doc.data().name;
          todosList.appendChild(listChild);
        });
      });
      hide(signedOut);
      show(signedIn);
    })
    .catch((error) => {
      console.error(error.message);
    });
});

signOutButton.addEventListener("click", () => {
  signOut(auth)
    .then(() => {
      hide(signedIn);
      show(signedOut);
      userDetails.textContent = "";
      clearTodosList();
    })
    .catch((error) => {
      console.error(error.message);
    });
});

newTodoForm.addEventListener("submit", (event) => {
  event.preventDefault();
  try {
    addDoc(userTodos, {
      name: newTodoName.value,
    });
    newTodoName.value = "";
  } catch (error) {
    console.error(error);
  }
});
